import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import ProfileImage from "../assets/images/profile.jpg"

const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <aside>
      <div className="aside-content">
        <div className="text-center">
          <Link to="/">
            <img
              src={ProfileImage}
              alt={data.site.siteMetadata.title}
              title={data.site.siteMetadata.title}
            />
            <h2>{data.site.siteMetadata.title}</h2>
            <h3>{data.site.siteMetadata.description}</h3>
          </Link>
        </div>
        <nav>
          <Link to="/" activeClassName="active">
            صفحه اصلی
          </Link>
          <Link to="/resume" activeClassName="active">
            رزومه
          </Link>
          <Link to="/portfolio" activeClassName="active">
            نمونه کارها
          </Link>
          <Link to="/blog" activeClassName="active">
            آموزش طراحی سایت رایگان
          </Link>
          <Link to="/contact" activeClassName="active">
            مشخصات تماس
          </Link>
        </nav>
        <ul className="social">
          <li>
            <a
              href="https://www.instagram.com/navid_behroozi_"
              target="_blank"
              rel="noreferrer noopener external"
              title="اینستاگرام"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://t.me/nbehroozi"
              target="_blank"
              rel="noopener noreferrer external"
              title="تلگرام"
            >
              <i className="fas fa-location-arrow"></i>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/BehrooziNavid"
              target="_blank"
              rel="noopener noreferrer external"
              title="توییتر"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </li>
        </ul>
        <p className="copyright">&copy; طراحی شده با ♥ توسط خودم</p>
      </div>
    </aside>
  )
}

export default Menu
