import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div className="row">
        <div className="col-md-4">
          <p>&copy; ۱۳۹۹ - کلیه حقوق محفوظ است.</p>
        </div>
        <div className="col-md-4 text-center">
          <Link to="/blog"><strong>آموزش طراحی سایت</strong></Link>
        </div>
        <div className="col-md-4">
          <ul className="social">
            <li>
              <a
                href="https://www.instagram.com/navid_behroozi_"
                target="_blank"
                rel="noreferrer nofollow external noopener"
                title="اینستاگرام"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                href="https://t.me/nbehroozi"
                target="_blank"
                rel="noreferrer nofollow external noopener"
                title="تلگرام"
              >
                <i className="fas fa-location-arrow"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/BehrooziNavid"
                target="_blank"
                rel="noreferrer nofollow external noopener"
                title="توییتر"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/navid-behroozi-9986ab174/"
                target="_blank"
                rel="noreferrer nofollow external noopener"
                title="لینکدین"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
