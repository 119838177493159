import React from "react"
import { Helmet } from "react-helmet"

import Menu from "../components/Menu"
import Footer from "../components/Footer"

const Layout = props => {
  return (
    <main>
      <div className="application">
        <Helmet>
          <meta name="google-site-verification" content="V_dgAUdLzM3xVduG5C79dtsn4e6GkelEUE0GBHIZTfs" />
          <meta name="Robots" content="index, follow, archive" />
          <meta http-equiv="Cache-control" content="public" />
        </Helmet>
      </div>
      <Menu />
      <div className="right-side">
        <div className="site-content">{props.children}</div>
        <Footer />
      </div>
    </main>
  )
}

export default Layout
